import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button } from "@material-ui/core"
import { Helmet } from "react-helmet"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Template currentPage="group" lightnessTheme="light">
      <Helmet>
        <title>Daya Selaras Group - Group</title>
      </Helmet>
      <div className={styles.fullPage}>
        <div className={styles.fence} style={{backgroundColor: "#d9d9d9", paddingTop: "128px", paddingBottom: "32px", boxSizing: "border-box"}}>
            <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                <div className={styles.titleWithDescription} style={{maxWidth: "400px"}}>
                  <div className={styles.titleWithDescription__title} style={{maxWidth: "200px"}}><span>GROUP</span> <span>AFFILIATION</span></div>
                  <div className={styles.titleWithDescription__description}>In order to realize our vision and mission, we strive to be fully engaged with our partners. Our company motto “Together We Win” aims to encourage our affiliated networks to contribute in the attainment of our companies’ vision.</div>
                </div>
                <div className={styles.responsiveImage} style={{maxWidth: "calc(100% - 400px)"}}>
                  <div className={styles.companySection}>
                    <div className={styles.companySection__logo} style={{backgroundImage: 'url("/static/images/ptdck-logo.png")'}}></div>
                    <div className={styles.companySection__title}>
                      <span>DAYA</span> <span>CIPTA</span> <span>KEMASINDO</span>
                    </div>
                    <div className={styles.companySection__description}>
                      A leading integrated corrugated carton manufacturer in Indonesia.<br />
                      <br />
                      Plant locations :<br />
                      Tangerang, Cibitung, Kerawang.<br /><br />
                    </div>
                    <a className={styles.companySection__buttonLink} href="/ptdck"><Button className={styles.companySection__button} color="primary" variant="contained">READ MORE</Button></a>
                  </div>
                  <div className={styles.companySection}>
                    <div className={styles.companySection__logo} style={{backgroundImage: 'url("/static/images/cks-logo.png")'}}></div>
                    <div className={styles.companySection__title}>
                      <span>CIPTA</span> <span>KELOLA</span> <span>SELARAS</span>
                    </div>
                    <div className={styles.companySection__description}>
                      An industrial logistics company providing added-value delivery and sustainable management in Indonesia.<br />
                      <br />
                      Geographical coverage :<br />
                      Java Island
                    </div>
                    <a className={styles.companySection__buttonLink} href="/cks"><Button className={styles.companySection__button} color="primary" variant="contained">READ MORE</Button></a>
                  </div>
                  <div className={styles.companySection}>
                    <div className={styles.companySection__logo} style={{backgroundImage: 'url("/static/images/dus-logo.png")'}}></div>
                    <div className={styles.companySection__title}>
                      <span>DAUR</span> <span>ULANG</span> <span>SELARAS</span>
                    </div>
                    <div className={styles.companySection__description}>
                      Recycling and paper waste management services for waste paper aggregators & customers.<br />
                      <br />
                      Netwotk locations :<br />
                      West & Central Java<br /><br />
                    </div>
                    <a className={styles.companySection__buttonLink} href="/dus"><Button className={styles.companySection__button} color="primary" variant="contained">READ MORE</Button></a>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </Template>
  }
}

export default IndexPage
